import { stringifyParams } from '@utils';
import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'productFilterForm',
    'pageSize',
    'perPage',
    'page',
    'previous',
    'next',
    'bulkUpdateCheckbox',
    'bulkUpdateButton',
    'defaultCost',
    'variantCost',
    'defaultCostButton',
    'ProviderAuthorizationSelect',
    'paginationChange',
    'noCost',
    'search'
  ];
  
  declare productFilterFormTarget: HTMLFormElement;
  declare pageSizeTarget: HTMLInputElement;
  declare pageTarget: HTMLInputElement;
  declare perPageTarget: HTMLInputElement;
  declare previousTarget: HTMLButtonElement;
  declare nextTarget: HTMLButtonElement;
  declare paginationChangeTarget: HTMLInputElement;
  declare bulkUpdateCheckboxTargets: HTMLInputElement[];
  declare bulkUpdateButtonTarget: HTMLButtonElement;
  declare defaultCostTarget: HTMLInputElement;
  declare variantCostTargets: HTMLInputElement[];
  declare defaultCostButtonTarget: HTMLButtonElement;
  declare ProviderAuthorizationSelectTarget: HTMLInputElement;
  declare noCostTarget: HTMLInputElement;
  declare searchTarget: HTMLInputElement;
  declare entityId: string;
  private parmsObj: ParamsObj = {};


  connect() {
    this.entityId = this.element.dataset.entityId;

    let productParams = new URLSearchParams(window.location.search);
    this.parmsObj.pagination = {
      page: parseInt(productParams.get('pagination[page]')) || 30,
      per_page: parseInt(productParams.get('pagination[per_page]')) || 1
    };
    this.parmsObj.filter ||= {}
  }

  setPageSize() {
    this.perPageTarget.value = this.pageSizeTarget.getAttribute('data-search-select-value');
    this.pageTarget.value = '1';
    this.productFilterFormTarget.requestSubmit();
  }

  checkboxUpdated() {
    const checkedCheckboxes = this.bulkUpdateCheckboxTargets.filter(checkbox => checkbox.checked);
    if(checkedCheckboxes.length > 0) {
      this.bulkUpdateButtonTarget.classList.remove('hidden');
    } else {
      this.bulkUpdateButtonTarget.classList.add('hidden');
    }
  }

  bulkUpdate(e) {
    e.preventDefault();

    const selectedVariants = this.bulkUpdateCheckboxTargets
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.getAttribute('data-source-id'));

    var proAuthValue = this.ProviderAuthorizationSelectTarget.getAttribute('data-search-select-value')
    
    const params = new URLSearchParams();
    selectedVariants.forEach(id => params.append('source_ids[]', id));
    params.append('provider_authorization_id', proAuthValue);
    
    (window as any).openPopup(`/products/${this.entityId}/bulk_update_view?${params.toString()}`);
  }

  applyDefaultCostToVariants(e) {
    e.preventDefault();
    if(Number(this.defaultCostTarget.value) < 0) {
        this.showAlert('Cost cannot be negative', { type: 'error' });
        return;
    }
    this.variantCostTargets.forEach((element) => {          
        element.value = this.defaultCostTarget.value;
    });
  }

  goToNextPage() {
    if(this.nextTarget.hasAttribute('disabled'))
      return;

    this.pageTarget.value = (parseInt(this.pageTarget.value) + 1).toString();
    this.parmsObj.pagination['from_pagination'] = true;
    this.paginationChangeTarget.value = 'true';
    this.productFilterFormTarget.requestSubmit();
    this.addParams();
  }

  goToPreviousPage() {
    if(this.previousTarget.hasAttribute('disabled'))
      return;

    this.pageTarget.value = (parseInt(this.pageTarget.value) - 1).toString();
    this.parmsObj.pagination['from_pagination'] = true;
    this.paginationChangeTarget.value = 'true';
    this.productFilterFormTarget.requestSubmit();
    this.addParams();
  }

  updateApplyAllButton() {
    if(this.defaultCostTarget.value == '') {
        this.defaultCostButtonTarget.classList.add('form-disabled');
        this.defaultCostButtonTarget.setAttribute("disabled", "true");
    } else {
        this.defaultCostButtonTarget.classList.remove('form-disabled');
        this.defaultCostButtonTarget.removeAttribute("disabled");
    }
  }

  exportData(event) {
    event.preventDefault();

    const form = this.productFilterFormTarget;
    let formData = new FormData(form);
    formData.append('commit', 'export_data');
    const queryParams = new URLSearchParams();

    formData.forEach((value, key) => {
      queryParams.append(key, value.toString());
    });

    window.open(form.action + "?" + queryParams.toString(), "_blank");
  }

  addParams() {    
    let filter_value = this.ProviderAuthorizationSelectTarget.getAttribute('data-search-select-value');
    this.parmsObj.filter['provider_authorization_id'] = filter_value;
    
    filter_value = this.searchTarget.value;
    this.parmsObj.filter['search'] = filter_value;
    
    filter_value = this.noCostTarget.checked == true ? 'on' : '';
    this.parmsObj.filter['no_cost'] = filter_value;

    this.parmsObj.pagination['per_page'] = parseInt(this.perPageTarget.value);
    this.parmsObj.pagination['page'] = parseInt(this.pageTarget.value);

    let url = '';
    const query = stringifyParams(this.parmsObj);
    if (query.length) {
        url = url + '?' + query;
        window.history.pushState({}, '', url);
    }
  }
}

interface ParamsPageObj {
  page?: number
  per_page?: number
  from_pagination?: boolean
}

interface ParamsObj {
  filter?: object;
  pagination?: ParamsPageObj;
}